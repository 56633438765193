export default {
  state: {
    user: {},
  },
  mutations: {
    updateUser(state, { type, payload }) {
      if (type) {
        state.user[type] = [...state.user[type], payload];
      } else {
        state.user = payload;
      }
    },
    resetUser(state) {
      state.user = {};
    },
    deleteUserPropreties(state, { type, payload }) {
      state.user[type] = state.user[type].filter(list => list.id !== payload);
    },

    // favorite
    addFavoris(state, payload) {
      state.user.favorie_job = [...state.user.favorie_job, payload];
    },
    deleteFavoris(state, payload) {
      state.user.favorie_job = state.user.favorie_job.filter(job => job.job_offer.id !== payload);
    },
  },
  actions: {
    /**
    * handle user change in store 
    * @param {String} type - type of user data : alert, candidature, conversation, favoris
    * - set to null for whole user object
    * @param {Object} payload - user data
    */
    handleUserChange({ commit }, { type, payload }) {
      commit("updateUser", { type, payload });
    },
    handleDeleteUserPropreties({ commit }, { type, payload }) {
      commit("deleteUserPropreties", { type, payload });
    },

    // favorite
    /**
    * Add a job offer to the user's favorite list
    * @param {Object} payload - The job offer to add
    * @returns {void}
    */
    handleAddFavoris({ commit }, payload) {
      commit("addFavoris", payload);
    },
    /**
     * Remove a job offer from the user's favorite list
     * @param {Object} payload - The job ID offer to remove
     * @returns {void}
     * */
    handleDeleteFavoris({ commit }, payload) {
      commit("deleteFavoris", payload);
    },
  },
  getters: {
    getUser: (state) => {
      // sort conversation by date modification
      if (state.user.conversation) {
        state.user.conversation.sort((a, b) => {
          return new Date(b.date_modification) - new Date(a.date_modification);
        });
      }
      return state.user;
    },
  },
};
