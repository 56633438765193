import axios from "axios";
import { getToken } from './account.service';

const axiosRequest = (contentType = "application/json") => {
  const token = getToken();

  return axios.create({
    // baseURL: "https://web.thanks-boss.com/api",
    // baseURL: "http://144.91.104.65:8000/api",
    baseURL: "https://webprod.thanks-boss.com/api",

    headers: {
      "Content-Type": contentType,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
};

export default axiosRequest;