import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/candidate/home/Home.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/candidate/contact/Contact.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/all/about/About.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/all/blog/Blog.vue"),
  },
  {
    path: "/blog-section/:sectiontype",
    name: "blog-section",
    component: () => import("@/views/all/blog-section/BlogSection.vue"),
  },
  {
    path: "/blog/:sectiontype/article/:articleid",
    name: "article",
    component: () => import("@/views/all/article/Article.vue"),
  },
  {
    path: "/cgu",
    name: "cgu",
    component: () => import("@/views/all/cgu/CGU.vue"),
  },
  {
    path: "/politique-confidentialite",
    name: "politique-confidentialite",
    component: () => import("@/views/all/politique-confidentialite/PolitiqueConfidentialite.vue"),
  },
  {
    path: "/cgv",
    name: "cgv",
    component: () => import("@/views/all/cgv/CGV.vue"),
  },
  {
    path: "/mentions-legales",
    name: "mentions-legales",
    component: () => import("@/views/all/mentions-legales/MentionsLegales.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/all/404/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/applicant",
});

export default router;
