import { toaster } from "../utils/toast/toast";
import axiosRequest from "./axios";

/**
 * submitEmailNewsletter 
 * @param {Object} formData
 * @return {Promise}
 * @throws {Error}
 */
const submitEmailNewsletter = async (formData) => {
  try {
    console.log(formData);
    await axiosRequest().post("/subscriptions/", formData);
    return toaster.showSuccessPopup("Inscription bien prise en compte !.");
  } catch (error) {
    console.error(error);
    if(error.response.status === 400) {
      return toaster.showErrorPopup("Vous êtes déjà inscrit à la newsletter.");
    }
    return toaster.showErrorPopup("Une erreur est survenue.");
  }
};


export { submitEmailNewsletter };