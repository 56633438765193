import axiosRequest from './axios';
import store from '@/store';
import { decodeCredential } from "vue3-google-login";
import { googleOneTap } from "vue3-google-login";
import { toaster } from "../utils/toast/toast"
/**
 * Login user by api
 * @param {Object} data
 * @param {Boolean} rememberMe
 * @return {Promise}
 * @throws {Error}
 */
const login = async (data, rememberMe) => {
  try {
    const response = await axiosRequest().post('/user/token/', data);

    // if (rememberMe) {
      saveTokenSession(response.data.access)
    // };

    const userResponse = await getUser();
    store.dispatch('handleUserChange', { type: null, payload: userResponse});

    store.commit('setLoggedIn', true);
    return;
  } catch (error) {
    throw new Error(error.response.data.detail || 'Login failed');
  }
};

/**
 * Login user by google one tap
 * @returns {Promise}
 * @throws {Error}
 */
const googleLogin = async () => {
  try {
    console.log('googleLogin');
    const response = await googleOneTap({
      clientId:
        "499109195466-3cgb1ucg8oon8ncdemjpveuqkv7n0i61.apps.googleusercontent.com",
      context: "signin",
    });

    const regData = decodeCredential(response.credential);

    // try to login
    const loginResponse = await login({
      email: regData.email,
      password: regData.sub,
    });
    // save token in local store
    saveTokenSession(loginResponse.data.access);

    // get user profile, and store it in vuex
    const userResponse = await getUser();
    store.dispatch('handleUserChange', { type: null, payload: userResponse.data });
    store.commit('setLoggedIn', true);
    return;
  } catch (error) {
    throw new Error(error.response.data.detail || 'Login failed');
  }
}

/**
 * Register user
 * @param {Object} credentials 
 * @returns {Promise}
 */
const register = async (credentials) => {
  try {
    return await axiosRequest().post('/user/register/', credentials)
  } catch(error) {
    throw new Error({'error': error, 'message': "Erreur lors de l'inscription"})
  } 
}
 
/**
 * Logout user
 */
const logout = () => {
  localStorage.removeItem('token')
  store.commit('setLoggedIn', false);
  store.dispatch('logout');
  toaster.showInfoPopup("Vous avez été déconnecté")
}

const deleteAccount = async () => {
  try {
    toaster.showInfoPopup("Votre compte est en cours de suppression. Vous allez être redirigé vers la page de connexion.");
    await axiosRequest().delete('/user/');
    logout();
    this.$router.push('/login');
  } catch (error) {
    toaster.showErrorPopup();
    console.error('Error deleting user account:', error);
  }
}
 
/**
 * Save token in local storage
 * @param {String} token
 * @returns {void}
 */
const saveTokenSession = (token) => {
  localStorage.setItem('token', token)
}

/**
 * Get token from local storage
 * @returns {String}
 */
const getToken = () => {
  return localStorage.getItem('token')
}

// à deplacer dans un fichier user.js
const getUser = async () => {
  try {
    const response = await axiosRequest().get('/user/');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.detail || 'User not found');
  }
}

const addUserToStore = async () => {
  const user = await getUser();
  store.dispatch('handleUserChange', { type: null, payload: user});
  store.commit('setLoggedIn', true);
}

export {
  login, googleLogin, register, logout, deleteAccount, saveTokenSession, getToken, getUser, addUserToStore
}