// store/index.js
import { createStore } from "vuex";
import UserModule from "./user/user.module";

export default createStore({
  modules: {
    userModule: UserModule,
  },
  state: {
    token: null,
    isLoggedIn: false,
  },
  mutations: {
    // log and token
    setToken(state, token) {
      state.token = token;
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    // alert
    updateAlert(state, payload) {
      state.userModule.user.alerte = [...state.userModule.user.alerte, payload];
    },
    deleteAlert(state, payload) {
      state.userModule.user.alerte = state.userModule.user.alerte.filter(alerte => alerte.id !== payload.id);
    },
    // candidature
    updateCandidature(state, payload) {
      state.userModule.user.postulation = [...state.userModule.user.postulation, payload];
    },
    // conversation
    addConversation(state, payload) {
      state.userModule.user.conversation = [...state.userModule.user.conversation, payload];
    },
    updateConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation);
      if (conversationIndex !== -1) {
        state.userModule.user.conversation[conversationIndex].messages = [
          ...state.userModule.user.conversation[conversationIndex].messages,
          payload
        ];
      }
    },
    updateTitleConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation_id);
      if (conversationIndex !== -1) {
        state.userModule.user.conversation[conversationIndex].titre = payload.titre;
      }
    },
  },
  actions: {
    // log and token
    saveToken({ commit }, token) {
      commit("setToken", token);
      commit("setLoggedIn", true);
    },
    logout({ commit }) {
      commit("setToken", null);
      commit("setLoggedIn", false);
      commit("resetUser");
    },
    handleLoggedIn({ commit }, value) {
      commit("setLoggedIn", value);
    },
    // alert
    handleAlertChange({ commit }, payload) {
      commit("updateAlert", payload);
    },
    handleDeleteAlert({ commit }, payload) {
      commit("deleteAlert", payload);
    },
    // candidature
    handleCandidatureChange({ commit }, payload) {
      commit("updateCandidature", payload);
    },
    // conversation
    handleAddConversation({ commit }, payload) {
      commit("addConversation", payload);
    },
    handleConversationChange({ commit }, payload) {
      commit("updateConversation", payload);
    },
    handleTitleConversationChange({ commit }, payload) {
      commit("updateTitleConversation", payload);
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    getToken: (state) => state.token,
  },
});
